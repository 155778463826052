var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isHidden || _vm.isDebug),expression:"!isHidden || isDebug"}],ref:"rootElement",class:{
    'oeos-countdown': true,
    'oeos-paused': _vm.paused,
    'oeos-hidden-timer': _vm.isHidden,
  },style:(_vm.cssVars)},[(!_vm.isHidden)?_c('svg',{class:{
      'oeos-countdown-bg': _vm.showLine,
      spin: _vm.isSecret,
    }},[_c('circle',{attrs:{"r":_vm.bgRadius,"cx":_vm.size / 2,"cy":_vm.size / 2}})]):_vm._e(),(!_vm.isHidden)?_c('svg',{class:{
      'oeos-countdown-line': _vm.showLine,
      spin: _vm.isSecret,
    }},[_c('circle',{attrs:{"r":_vm.lineRadius,"cx":_vm.size / 2,"cy":_vm.size / 2}})]):_vm._e(),_c('div',{staticClass:"oeos-countdown-number"},[_vm._v(_vm._s(_vm.formattedTimeLeft))]),_c('div',{staticClass:"oeos-countdown-skip"},[(_vm.isDebug)?_c('v-btn',{attrs:{"elevation":"1","x-small":""},on:{"click":_vm.onTimesUp}},[_vm._v("skip")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }